// Animation loading page
let timer = setInterval(onLoad, 250);
let char = 0;
 function onLoad() {
  const lettersLoadingFirst = document.querySelectorAll('.loading-text-words')[char];
  const lettersLoadingAfter = document.querySelectorAll('.loading-text-words')[char+1];
  lettersLoadingFirst.classList.add('loading-text-anim');
  lettersLoadingAfter.classList.add('loading-text-anim');
  char++;
  if (char === 6){
    complete();
    return;
  }
}
 function complete(){
    clearInterval(timer);
    timer = null;
}


// Animation my name
var tl = anime.timeline({ 
         loop: 2
      })

tl.add({
    targets: '.ml5 .line',
    opacity: [0.5,1],
    scaleX: [0, 1],
    easing: "linear",
    duration: 1000
  })
  .add({
    targets: '.ml5 .line',
    duration: 450,
    easing: "linear",
    translateY: (el, i) => (-0.625 + 0.625*2*i) + "em",
    opacity: 0
  })
 .add({
    targets: '.ml5 .letters-left',
    opacity: [0,1],
    translateX: ["0.5em", 0],
    easing: "easeOutExpo",
    duration: 760,
    offset: '-=300'
  })
  .add({
    targets: '.ml5 .letters-right',
    opacity: [0,1],
    translateX: ["-0.5em", 0],
    easing: "easeOutExpo",
    duration: 760,
    offset: '-=600'
  });

  // Display front-end
  function displayFrontend() {
    setTimeout(function(){ document.querySelector('.devFront').style.setProperty("opacity","1"); }, 3700);
  }
  document.querySelector(".ml5").addEventListener("load", displayFrontend());

  // Animation WhoIam
  function scrollWhoIam() {
      const text = document.querySelector(".whoIam");
      const textNoHtml = text.textContent;
      const splitText = textNoHtml.split("");
      text.textContent = "";
      text.classList.add('whoIamAppear');
      for( let i=0; i<splitText.length; i++){
        text.innerHTML += "<span class='whoIamSplit'>" + splitText[i] + "</span>";
      }

      let char = 0;
      let timer = setInterval(onTick, 30);

      function onTick() {
        const span = text.querySelectorAll('span')[char];
        span.classList.add('fade');
        char++;
        if (char === splitText.length){
          complete();
          return;
        }
      }

      function complete(){
        clearInterval(timer);
        timer = null;
      }
}

  var isWhoIamDisplayed = true;
  function whoIamAppear() {
    var whoIamText = document.querySelector('.whoIam');
    var whoPosition =  whoIamText.getBoundingClientRect().top;
    var screenPosition = window.innerHeight /1.3;
   

    if((whoPosition < screenPosition) && isWhoIamDisplayed) {
      scrollWhoIam();
      isWhoIamDisplayed = false;
    }
  }
  window.addEventListener('scroll', whoIamAppear);

  var isDescriptionDisplayed = true;
  function descriptionAppear() {
    var descriptionTextOne = document.querySelector('.text-center-one');
    var descriptionPosition =  descriptionTextOne.getBoundingClientRect().top;
    var screenPosition = window.innerHeight /1.3;
    
     if((descriptionPosition < screenPosition) && isDescriptionDisplayed) {
      const descriptionOne = document.querySelector('.text-center-one');
      const descriptionTwo = document.querySelector('.text-center-two');
      descriptionOne.classList.add('description-appear');
      setTimeout(function(){ descriptionTwo.classList.add('description-appear');}, 250);
      isDescriptionDisplayed = false;
    }
  }
  window.addEventListener('scroll', descriptionAppear);
  
  var isIconDisplayed = true;
  function displaySkillIcon() {
    var iconSkill = document.querySelector('.fa-html5');
    var iconPosition = iconSkill.getBoundingClientRect().top;
    var screenPosition = window.innerHeight / 1.6;

    if((iconPosition < screenPosition) && isIconDisplayed) {
     const html = document.querySelector('.fa-html5');
     const css = document.querySelector('.fa-css3-alt');
     const js = document.querySelector('.fa-js-square');
     const angular = document.querySelector('.fa-angular');
     const node = document.querySelector('.fa-node'); 
     html.classList.add('icon-appear');
     setTimeout(function(){ css.classList.add('icon-appear'); }, 250);
     setTimeout(function(){ js.classList.add('icon-appear'); }, 500);
     setTimeout(function(){ angular.classList.add('icon-appear'); }, 1000);
     setTimeout(function(){ node.classList.add('icon-appear'); }, 1250);
     isIconDisplayed = false;
    }
  }
  window.addEventListener('scroll', displaySkillIcon);

  // When scrolls down, hide the navbar. When the user scrolls up, show the navbar 
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (currentScrollPos > 100 ) {
      
      document.getElementById("mainNav").style.top = "-50px";
  } else {
    document.getElementById("mainNav").style.top = "0";
  } 
  }

  // Special focus on services
  const allBoxes = Array.from(document.querySelectorAll('.col-service'));
  let currentIndex = 0;
  allBoxes.forEach(box => {

    box.addEventListener('mouseenter', e => {
      const el = e.target;
      currentIndex = allBoxes.indexOf(el)

      allBoxes.forEach((box, index) => {
        if (index === currentIndex) return;
        box.classList.add('blur-box');
      })
    })

    box.addEventListener('mouseleave', () => {
      allBoxes.forEach((box, index) => {
        if (index === currentIndex) return;
        box.classList.remove('blur-box');
      })

    })

  })
  
  // Focus textarea form
  const areaForm = document.getElementById("areaForm");

  //Send Email function
  function sendEmail(){
    var valueOK = true;
    const testEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const testName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/; 
    if (Form.user_email.value != null && testEmail.test(Form.user_email.value)) {
      var fromEmail = "Mon email est " + Form.user_email.value
    } else {
      valueOK = false
      swal("Email non valide");
    }
    if(Form.user_name.value != null && testName.test(Form.user_name.value)) {
    var username = Form.user_name.value
    } else {
      valueOK = false
      swal("Nom non valide");
    }
    if (Form.message.value != null) {
    var bodymsg = Form.message.value
    } else {
      valueOK = false;
      swal("Message non valide");
    }
    if( valueOK) {
      console.log("valueOK");
    Email.send({
      SecureToken : '52e8be0d-8a5e-4539-9c67-505c6ab54775',
      To : 'contact@stephane-rc.fr',
      From : 'contact@stephane-rc.fr',
      Username : username,
      Subject: 'Demande information portfolio',
      Body : "<html><h2>Sender Email is </h2>" + fromEmail + "<br></br>" + bodymsg + "</html>"
  }).then(
    message => swal({
      title:"Message envoyé !",
      text: "Je vous répondrai sous 48h"
    })
  );
  }
  }

  document.getElementById('buttonEmail').addEventListener('click', (event) => {
    event.preventDefault();
    sendEmail();
  });

  // Validation input name
  const inputName = document.querySelector('.input-name');
  const validationName = document.querySelector('.field-name span');
  const testName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/; 

  inputName.addEventListener('input', function(e) {
      if( (testName.test(e.target.value)) || e.target.value === ''){
        inputName.classList.remove('erreur');
        validationName.classList.remove('visible');
    } else {
        inputName.classList.add('erreur');
        validationName.innerText = "Champ non valide";
        validationName.classList.add('visible');
    }
})

 // Validation input mail
 const inputMail = document.querySelector('.input-mail');
 const validationMail = document.querySelector('.field-mail span');
 const testEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

 inputMail.addEventListener('input', function(e) {
    if ( (testEmail.test(e.target.value)) || e.target.value === '') {
      inputMail.classList.remove('erreur');
      validationMail.classList.remove('visible');
   } 
   else {
       inputMail.classList.add('erreur');
       validationMail.innerText = "Email non valide";
       validationMail.classList.add('visible');
   }
})


 // e6c011b0-0e1a-48eb-900b-811169040026

  /*'1f8c2dc7-1360-4358-8456-efc9cbbcbb92'
  'host mail.stephane-rc.fr'
  'username   contact@stephane-rc.fr'
  'Domain   stephane-rc.fr'
  'port 2083'*/

/* user:  contact@stephane-rc.fr
mdp: 61AC803E6727273C36187300F3996EC49DA5
server smtp.elasticemail.com
port 2525

  52e8be0d-8a5e-4539-9c67-505c6ab54775
*/